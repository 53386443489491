"use strict";

// All classes are copied to contenteditable div
// Requirements:
// 1) input text should be absolutely positioned and should not have background color.
// 2) When horisontal padding required, use left/right properties instead with zero padding
// 3) trigger "change" event when set value to input programmatically

(function ($) {
  var cssClassName = "RichInputBox" + new Date().getTime();

  function embedStyle(style) {
    var s = document.createElement("style");
    s.type = "text/css";
    document.body.appendChild(s);
    s.appendChild(document.createTextNode(style));
  }
  
  function debounce(func, delay, immediateFirstCall) {
    var lastCallTime = 0, timeout = null;
    return function() {
      var now = new Date().getTime();
      if(now - lastCallTime > delay && immediateFirstCall) {
        func();
        lastCallTime = now;
      } else {
        if(timeout !== null) return;
        timeout = setTimeout(function() {
          func();
          lastCallTime = new Date().getTime();
          timeout = null;
        }, delay - (now - lastCallTime > delay ? 0 : now - lastCallTime));
      }
    }
  }
  
  function RichInputBox(input) {
    var div = $("<div>");
    div.addClass(input[0].className + " " + cssClassName).prop("contenteditable", "true").insertBefore(input);
    input.css({
      "pointer-events": "none",
      "background-color": "transparent",
    });
    if(input[0].tagName.toUpperCase() == "INPUT") {
      div.css({
        "white-space": "pre",
        "word-wrap": "normal",
        "overflow": "hidden"
      });
    } else {
      div.css({
        "overflow-x": "hidden",
        "overflow-y": "auto",
      });
    }
    
    function syncInputValue() {
      var txt = "";
      var cleanup = false;
      var children = div[0].childNodes;
      
      function appendTextPart(part) {
        if(!part || part.length == 0) return;
        if(txt.length > 0 && txt[txt.length-1] != ' ' && part[0] != ' ') txt += ' ';
        txt += part;
      }
      
      for(var i=0; i<children.length; i++) {
        if(children[i].nodeType == 3) {
          appendTextPart(children[i].nodeValue);
        } else if(children[i].nodeType == 1 && children[i].nodeName.toUpperCase() == "IMG") {
          appendTextPart(children[i].getAttribute("data-text-alternative"));
        } else {
          appendTextPart(children[i].innerText);
        }
      }
      if(children.length == 1 && children[0].nodeType == 1 && children[0].nodeName.toUpperCase() == "BR") {
        div[0].removeChild(children[0]);
      }
      input.val(txt);
    }
    
    var saveSelectionRange = debounce(function() {
      if(div.data("focused")) {
        var sel = window.getSelection();
        if(sel.rangeCount > 0) {
          var range = sel.getRangeAt(0);
          div.data("selectionRange", range);
        } else {
          div.removeData("selectionRange");
        }
      }
      
      syncInputValue();
    }, 100);
    
    div.on("keypress", function(event) {
      if(event.keyCode == 13) {
        syncInputValue();
        input.trigger(event);
        event.stopPropagation();
        event.preventDefault();
      }
    }).on("paste", function(event) {
      event.stopPropagation();
      event.preventDefault();

      if($.fn.EmojiPicker) {
        var items = event.originalEvent.clipboardData ? event.originalEvent.clipboardData.items : null;
        if(items && items.length == 1 && items[0].type.indexOf("image") !== -1) {
          var img = document.createElement("IMG");
          img.src = $.fn.EmojiPicker.pasteImage(
            items[0].getAsFile(),
            function(url) {
              img.src = url;
              img.setAttribute("data-text-alternative", url);
              syncInputValue();
            }, 
            function() {
              img.parentElement.removeChild(img);
            }
          );
          input.trigger("Action:InsertImage", [img, ""]);
          return;
        }
      }
      
      var text = event.originalEvent.clipboardData.getData("text/plain");
      if(text.length > 0) {
        text = text.replace(/[\r\n]+/g, ' ');
        document.execCommand("insertText", false, text);
      }
    }).on("focus", function() {
      div.data("focused", true);
    }).on("blur", function() {
      div.data("focused", false);
    }).on("input keyup mouseup focus blur cut paste Action:SaveSelectionRange", saveSelectionRange);
    
    input.on("change", function() {
      div.text(input.val());
    }).on("focus", function(event) {
      event.stopPropagation();
      event.preventDefault();
      setTimeout(function() { div.focus(); }, 0);
    }).on("Action:InsertImage", function(event, element, textAlternative) {
      if(element instanceof jQuery) element=element[0];
      if(element.tagName.toUpperCase() != "IMG") return;
      
      element.setAttribute("data-text-alternative", textAlternative);
      div.focus();
      var sel = window.getSelection();
      var range = div.data("selectionRange");
      if(range) {
        range.deleteContents();
      //   range.insertNode(element);
      } else {
        // div.append(element);
        range = sel.getRangeAt(0);
      }
      div.append(element);

      range.setStartAfter(element);
      range.collapse(true);
      // range.insertNode(document.createTextNode(" ")); // Webkit wrap images if there is no text so we put space
      sel.removeAllRanges();
      sel.addRange(range);
      if(element.offsetLeft+element.offsetWidth+10> div[0].scrollLeft + div[0].clientWidth) {
        div[0].scrollLeft = element.offsetLeft+element.offsetWidth+10 - div[0].clientWidth;
      }
      div.data("selectionRange", range);
    });
  }
  
  if(!$.fn.RichInputBox) {
    $.fn.RichInputBox = function() {
      return this.each(function() {
        var input = $(this);
        input.data("RichInputBox", input.data("RichInputBox") || new RichInputBox(input)); 
      });
    };
    
    embedStyle(
      "." + cssClassName + " {" +
      "display: inline-block !important;" +
      "cursor: text !important;" +
      "}" + 
      "." + cssClassName + ":not(:empty)+input[type=text], ." + cssClassName + ":not(:empty)+textarea {" +
      " display: none;" +
      "}" +
      "." + cssClassName + " img {" +
      "max-height: calc(100% - 4px);" +
      "margin: 2px 2px;" +
      "padding: 0 2px;" +
      "display: inline-block;" +
      "vertical-align: middle;" +
      "width: auto !important;" +
      "height: 100% !important; // maximize image by height" +
      "}" +
      "." + cssClassName + " img.emojione {" +
      "width: auto !important;" +
      "height: 1.5em !important;" +
      "}"
    );
  }

}(window.jQuery));